import React, { useEffect, useState } from 'react';
import { AppContext, AppInitialProps } from 'next/app';
import ConnectedIntlProvider, { LangContext } from '../src/ConnectedIntlProvider';
import Favicon from '../components/head';

import "../public/styles/stylesGlobalCSS.css"
import { Provider, useSession } from 'next-auth/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;


import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";


import Intercom from "@intercom/messenger-js-sdk";
import { getCookie, setCookie } from 'cookies-next';
import * as Sentry from '@sentry/nextjs';


//Set your APP_ID
var APP_ID = "jlq7hw5m";

const getBrowserLocale = () => {
    try {
        return window.navigator.language.split('-')[0].toLowerCase();
    } catch {
        return 'en';
    }
};


const MyApp = ({ Component, pageProps }: AppContext & AppInitialProps) => {
    const [language, setLanguage] = useState(getBrowserLocale()); // default language depends on browser settings
    const [ session, loading ] = useSession();

    const [ userEmail, setUserEmail ] = useState();

    const [ userName,  setUserName] = useState();

    const [ userUUID, setUserUUID ] = useState();
    const [ userPhone, setUserPhone ] = useState();
    const [ companyUUID, setCompanyUUID ] = useState();

    const [ companyName, setCompanyName ] = useState();
    const [ apiVersion, setAPIversion ] = useState("0.0.0");


    const loadTeamInfoAPI = () => {
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"teams/current", {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data){

                if(data.message !== undefined){
                    Sentry.captureException("teams/current"+": "+ data.message + " : " + getCookie("userMail"));

                }

                setCompanyName(data.name);


            });
    };

    const loadUserInfoAPI = () => {
        var tokenMain;

        if( getCookie('userTeam')){
            tokenMain = getCookie('userTeam');
        } else {
            if(session && session.accessToken){
                tokenMain = session.accessToken;
            } else {
                tokenMain = null;
            }
      //      tokenMain = session.accessToken;
        }


        if(tokenMain){
            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/info', {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer ' + tokenMain, // notice the Bearer before your token
                },
            })
                .then(response => response.json())
                .then(function(data) {


                    if (data.message !== undefined) {
                        Sentry.captureException('auth/info' + ': ' + data.message + ' : ' + getCookie('userMail'));
                    } else {


                        setUserEmail(data.user.email);
                        setUserName(data.user.full_name);
                        setUserUUID(data.user.uuid);
                        setUserPhone(data.user.phone);
                        setCompanyUUID(data.current_team);

                        setAPIversion(data.api_version);

                    }

                });
        }


    };

    useEffect(() => {
        loadTeamInfoAPI();
        loadUserInfoAPI();
    }, []);
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
        if(userName && userUUID && userEmail && companyUUID&&companyName && isClient){

            const crypto = require('crypto');

            const secretKey = 'ijkj7eBH1IzkIJNkPBuSXeHwnyvnOoSpRHT7PWSm'; // IMPORTANT: your web Identity Verification secret key - keep it safe!
            const userIdentifier = userUUID; // IMPORTANT: a UUID to identify your user

            const hash = crypto.createHmac('sha256', secretKey).update(userIdentifier).digest('hex');

            var newWidth = null;
            var newHeight = null;
                    if(window){
                        newWidth= window.innerWidth;
                        newHeight= window.innerHeight;
                    }




                Intercom({
                    app_id: APP_ID,
                    action_color: '#3D55D9',
                    background_color : '#3D55D9',
                    web_app_version: '0.6.1',
                    plugin_version: '1.0.3',
                    api_version: apiVersion,
                    language:language,
                    resolutionWidth:newWidth,
                    resolutionHeight: newHeight,
                    environment: process.env.NEXT_PUBLIC_NODE_ENV,
                    user_id: userUUID, // IMPORTANT: Replace “user.id” with the variable you use to capture the user’s ID
                    name: userName, // IMPORTANT: Replace “user.name” with the variable you use to capture the user’s name
                    email: userEmail, // IMPORTANT: Replace “user.email” with the variable you use to capture the user’s email
                    phone:userPhone,
                    company:{
                        id:companyUUID,
                        name:companyName
                    },
                    created_at: Date.now(), // IMPORTANT: Replace “user.createdAt” with the variable
                    user_hash:hash
            })
        }
    }, [userUUID,companyUUID,companyName,
        userName,isClient,
        userEmail]);



    useEffect(() => {
/*

        CookieConsent.run({
            categories: {
                necessary: {
                    enabled: true,  // this category is enabled by default
                    readOnly: true  // this category cannot be disabled
                },
                analytics: {

                }
            },
            mode : "opt-in",
            autoShow: true,
            guiOptions : {
                consentModal: {
                    layout: 'box',
                    position: 'bottom left',
                    flipButtons: false,
                    equalWeightButtons: true
                }
            },
            language: {
                default: language === "cs" ? "cs" : "en",
                translations: {
                    cs: {
                        consentModal: {
                            title: 'Na vašem soukromí nám záleží',
                            description: "Na tomto webu používáme soubory cookies k zajištění funkčnosti webu a s vaším souhlasem i cookies k analýze výkonu našich webových stránek. Kliknutím na tlačítko „Souhlasím“ souhlasíte s využíváním všech typů používaných cookies a předáním údajů o chování na webu. Více o souborech cookie.",
                            acceptAllBtn: 'Souhlasím',
                            acceptNecessaryBtn: 'Nesouhlasím',
                            showPreferencesBtn: 'Podrobné nastavení'
                        },
                        preferencesModal: {
                            title: 'Podrobné nastavení cookies',
                            acceptAllBtn: 'Souhlasím',
                            acceptNecessaryBtn: 'Nesouhlasím',
                            savePreferencesBtn: 'Uložit nastavení',
                            closeIconLabel: 'Zavřít okno',
                            sections: [
                                {
                                    description: 'Cookies na našem webu používáme k zajištění základních funkcí webu a k analýze výkonu webu. Souhlas pro používání cookies můžete kdykoliv změnit. Více o souborech cookie.'
                                },
                                {
                                    title: 'Nezbytné cookies',
                                    description: 'Technické cookies jsou nezbytné pro správné fungování webu a všech funkcí. Tyto soubory zajišťují základní funkce stránky jako je navigace nebo přihlášení. Bez technických cookies by náš web nebyl funkční, proto jsou ve výchozím nastavení povoleny a nelze je zakázat. Tyto soubory cookies neukládají žádné osobně identifikovatelné informace.',
                                    linkedCategory: 'necessary'
                                },
                                {
                                    title: 'Analytické cookies',
                                    description: 'Analytické cookies shromažďujeme pro měření výkonu našeho webu a reklamních kampaní. Pomocí nich určujeme kolik uživatelů přišlo na náš web a y jakého zdroje. Tato data zpracováváme souhrnně, bez možnosti ukázání na konkrétní uživatele našeho webu. Sbírání těchto cookies nemusíte povolit. Pokud je nepovolíte, ztratíme možnost kompletní analýzy našeho webu a následné optimalizaci.',
                                    linkedCategory: 'analytics'
                                }
                            ]
                        }
                    },
                    en: {
                        consentModal: {
                            title: 'Your privacy is important to us',
                            description: "On this website, we use cookies to ensure the functionality of the website and, with your consent, cookies to analyze the performance of our website. By clicking the \"Accept all\" button, you agree to the use of all types of cookies used and the transfer of data about behavior on the website. More about cookies.",
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Reject all',
                            showPreferencesBtn: 'Manage preferences'
                        },
                        preferencesModal: {
                            title: 'Cookie preferences',
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Reject all',
                            savePreferencesBtn: 'Save preferences',
                            closeIconLabel: 'Close',
                            sections: [
                                {
                                    description: 'We use cookies on our website to ensure basic website functions and to analyze website performance. You can change your consent to the use of cookies at any time. More about cookies.'
                                },
                                {
                                    title: 'Necessary cookies',
                                    description: 'Technical cookies are necessary for the proper functioning of the website and all functions. These files ensure the basic functions of the site such as navigation or login. Our website would not function without technical cookies, therefore they are enabled by default and cannot be disabled. These cookies do not store any personally identifiable information.',
                                    linkedCategory: 'necessary'
                                },
                                {
                                    title: 'Analytic cookies',
                                    description: 'We collect analytical cookies to measure the performance of our website and advertising campaigns. We use them to determine how many users came to our website and from which source. We process this data in aggregate, without the possibility of pointing to specific users of our website. You do not have to allow the collection of these cookies. If you do not allow them, we will lose the possibility of a complete analysis of our website and subsequent optimization.',
                                    linkedCategory: 'analytics'
                                }
                            ]
                        }
                    },
                }
            }
        });
*/



    }, []);
    return (



            <LangContext.Provider value={{ language, setLanguage }}>
                {/*     <LanguagePicker />*/}
                <ConnectedIntlProvider>
                    <Provider
                        // Provider options are not required but can be useful in situations where
                        // you have a short session maxAge time. Shown here with default values.
                        options={{
                            // Client Max Age controls how often the useSession in the client should
                            // contact the server to sync the session state. Value in seconds.
                            // e.g.
                            // * 0  - Disabled (always use cache value)
                            // * 60 - Sync session state with server if it's older than 60 seconds
                            //  clientMaxAge: 0,
                            // Keep Alive tells windows / tabs that are signed in to keep sending
                            // a keep alive request (which extends the current session expiry) to
                            // prevent sessions in open windows from expiring. Value in seconds.
                            //
                            // Note: If a session has expired when keep alive is triggered, all open
                            // windows / tabs will be updated to reflect the user is signed out.
                            //     keepAlive: 0
                        }}
                        session={pageProps.session} >

                        {/*<CookieConsent*/}
                        {/*    location="bottom"*/}
                        {/*    declineButtonText="Decline"*/}
                        {/*    buttonText="Accept"*/}
                        {/*    contentClasses="cookiesBar"*/}
                        {/*    cookieName="myAwesomeCookieName2"*/}
                        {/*    style={{ background: "black" }}*/}
                        {/*    enableDeclineButton*/}
                        {/*    onDecline={() => {*/}
                        {/*        alert("nay!");*/}
                        {/*    }}*/}
                        {/*    declineButtonStyle={{ color: "white", background: "darkred", fontSize: "13px",borderRadius:"22px",padding:"10px",paddingRight:"30px",paddingLeft:"30px" }}*/}
                        {/*    buttonStyle={{ color: "white", background: "#2035a5", fontSize: "13px",borderRadius:"22px",padding:"10px",paddingRight:"30px",paddingLeft:"30px" }}*/}
                        {/*    expires={150}*/}
                        {/*>*/}

                        {/*    This website uses cookies to enhance the user experience.{" "}*/}
                        {/*    <span style={{ fontSize: "10px" }}><a href={"https://www.obalero.com/"} target={"_blank"}>Full cookies here</a></span>*/}
                        {/*</CookieConsent>*/}


                        <Component {...pageProps} />
                        <Favicon />
                    </Provider>
                </ConnectedIntlProvider>
            </LangContext.Provider>
    );
};

export default MyApp;
